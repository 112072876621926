<template>
  <div id="CommentAssignedForm">
  
   
        <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
      />
      <!-- <v-expand-transition> -->
      <!-- <div v-show="expand" no-gutters> -->
         <v-row style="">
                   
                    <v-col :cols=8>  <v-select
                    v-model="username"
          :items="userdata"
          label="Assign User"
           item-text="userName"
                                item-value="userID"
                                dense
                                chips
                                small-chips
                               
        ></v-select></v-col>
                 

             </v-row>
             <v-row style="padding-top:10px">
                   
                    <v-col :cols=8>
                        <v-textarea
                        counter
                         :rules="rules"
                        v-model="comment"
          outlined
          name="input-7-0"
          label="Comment"
         
        ></v-textarea>
                    </v-col>
                 

             </v-row>
             <v-row>
                   <v-col :cols=8> <v-file-input
                   v-model="attachments"
                   dense
    multiple
    label="File input"
  ></v-file-input></v-col>
                  
                 

             </v-row>
              <v-card-actions>
         
           
        
          <v-btn
          small
            color="primary"
            
            @click="saveComment"
          >
            Save
          </v-btn>
             <v-btn
          small
            color="default"
            
            @click="close_comment"
          >
            Cancel
          </v-btn>
        </v-card-actions>

    
       <v-data-table
            :headers="headers"
             :height="comment_data.length>1? '40vh' : 'auto'"
            hide-default-footer
            :fixed-header="fixed_header"
            disable-pagination
            :items="comment_data"
            class="elevation-1"
          >
          <template v-slot:item.comments="{ item }">
          <span>{{ item.comments }}</span>
        </template>
        <template v-slot:item.attachments="{ item }">
         <v-icon v-for="att in item.attachments"
           :key="att.id"
              @click="openattachment(att.file_name)"
              
              class="mr-2"
              :title="att.file_name"
              >mdi-file-download-outline
</v-icon>  
  
        </template>
        <template v-slot:item.assignee_name="{ item }">
          <span>{{ item.assignee_name }}</span>
        </template>
        <template v-slot:item.reporter_name="{ item }">
          <span>{{ item.reporter_name }}</span>
        </template>
         <template v-slot:item.modify_date="{ item }">
          <span v-if="item.modify_date">{{ formateDate(item.modify_date) }}</span>
        </template>


       </v-data-table>

  
      
  
  </div>
</template>
<style type="text/css">
.theader {
    font-size: 11px;
    font-weight: bold;
    color: #000 !important;
}

</style>

<script>
import { mapGetters } from "vuex";
import Autocomplete from "./Autocomplete.vue";
import Loading from "vue-loading-overlay";
export default {
  name: "searchForm",
  data: () => ({
    fullPage:true,
   open_comment_dialog:false,
   comment_data:[],
   isLoading:false,
   rules: [v => v.length <= 500 || 'Max 500 characters'],
   comment:'',
   username:'',
   attachments:null,
   fixed_header:true,
   headers:[{
                    text: 'Comment',
                    sortable:false,
                    value: 'comments',
                    class: "v-data-table-header",
                     width:"40%",
        },
        {
                    text: 'Attachments',
                    sortable:false,
                    value: 'attachments',
                    class: "v-data-table-header",
                     width:"20%",

        } ,
         {
                    text: 'Date',
                    sortable:false,
                    value: 'modify_date',
                    class: "v-data-table-header",
                     width:"20%",

        } ,
         {
                    text: 'Assignee',
                    sortable:false,
                    value: 'assignee_name',
                    class: "v-data-table-header",
                     width:"10%",

        },{
                    text: 'Reported',
                    sortable:false,
                    value: 'reporter_name',
                    class: "v-data-table-header",
                     width:"10%",

        }],
  }),
  components: {
    Autocomplete,
    Loading,
  },
  props: ["userdata","comment_close_click","comment_product_id","comment_data"],
  mounted() {
    let view = this;
    //view.getRecords()
    // if (this.$utils.getCookies("advancefilter") != null) {
    //   view.expand = view.$utils.getCookies("advancefilter") === true;
    //   view.switch1 = view.$utils.getCookies("advancefilter") === true;
    // }
  },
  computed: {},
  methods: {
        formateDate: function (d) {
      return this.$utils.formateDate(d);
    },
    
    close_comment:function(){
     var view = this
     this.username = null
     
     view.userName=""
     view.comment= ""
     view.attachments=null

      view.$emit('close_comment_dialog', true)
    },
    openattachment:function(file_path){
      var view = this
      let ep = view.$url("UPLOAD_FILE")+"?file_path="+file_path+"&bucket_name="+view.$productrouteconst.PRODUCT_BUCKET_NAME
      if(file_path !=""){
        view.$fetch({ requiresAuth: true, operation : 'view', vueScope: view  })
        .get(ep).then(response=>{
          if(response.status == 200){
             window.open(response.data.payload.url, '_blank');
            //  console.log(response.data.payload.url)

          }
         
        })


      }

    },
    getRecords:function(){
      var view = this
      let endpoint = view.$url("ASSING_USER_URL")+"?product_id="
       //let endpoint = "http://0.0.0.0:5054/compare_pdf/12"
      view.$fetch({ requiresAuth: true })
      .get(endpoint+view.comment_product_id)
      .then((response) => {
        view.comment_data = response.data.assigned;
        view.isLoading=false
      })
      .catch((e) => console.log(e));

    },
    saveComment:function(){
      var view = this
      view.isLoading = true
      let PRODUCT_BUCKET_NAME = view.$productrouteconst.PRODUCT_BUCKET_NAME

      let user_id = this.$utils.getCookies('user_id')
      let assign_ep = view.$url("ASSING_USER_URL")
      let ATTACHMENT_BASE_PATH = view.$url("CSV2_PRODUCT_BUCKET_PATH")
      if(view.username == "" || view.comment== ''){
          this.$swal.fire({
              icon: "error",
              title: "Error",
              text: "Please enter comment and choose user to be assigned."
            });
             view.isLoading = false
            return ;
        }
      let full_file_path = []
      let post_form_data = {}
      post_form_data["product_id"] = this.comment_product_id
      post_form_data["reporter_id"] = user_id
      post_form_data["assignee_id"] = this.username
      post_form_data["comments"] = this.comment
      post_form_data["attachments"] = full_file_path

      if(view.attachments !== null){
         let all_file_uploads_url = this.$url("UPLOAD_FILE");
         //let all_file_uploads_url = "http://0.0.0.0:5417/fileupload";
        var formData = new FormData();
        
        for (let file of this.attachments) {
          let fpath = ATTACHMENT_BASE_PATH+this.comment_product_id+"/"+file.name
          full_file_path.push(fpath)
          formData.append("file", file);
        }
        formData.append(
          "destination",
          ATTACHMENT_BASE_PATH+this.comment_product_id+"/"
        );
        formData.append(
          "bucket_name",PRODUCT_BUCKET_NAME
        );
          this.$fetch({
          requiresAuth: true,
          operation: "add",
          vueScope: view,
          }).post(all_file_uploads_url, formData, {
          headers: {
            // 'Authorization': accestoken,
            "Content-Type": "multipart/form-data",
          },
        }).then(response=>{
          console.log(response)
          this.$fetch({
          requiresAuth: true,
          operation: "add",
          vueScope: view,
          }).post(assign_ep,post_form_data).then(res=>{
             view.username=null
            view.comment="" 
            view.attachments=null
            view.isLoading = false
            view.getRecords()
            this.$swal.fire({
              icon: "success",
              title: "Record saved successfully.",
              text: "Record saved successfully."
            });

          })
         
        })

      }else{
        
        this.$fetch({
          requiresAuth: true,
          operation: "add",
          vueScope: view,
          }).post(assign_ep,post_form_data).then(res=>{
             view.username=null
            view.comment= ""
            view.attachments=null
            view.isLoading = false
            view.getRecords()
            this.$swal.fire({
              icon: "success",
              title: "Record saved successfully.",
              text: "Record saved successfully."
            });
            

          })
        
      }

      
     
      
  
    }
   
  },
  watch: {
    
    
 
  },
};
</script>


<style scoped lang="sass">
@import './../../../sass/searchform'
#searchForm__container div.col
  padding-top: 1px
  padding-bottom: 1px
#searchForm__container div.row
  margin-top: 0px
#searchForm__container
  padding-top: 0px
</style>
